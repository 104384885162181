<template>
  <div>
    <CCardBody class="c-card-body">
      <router-link :to="`/participants/stats`">
        <CButton color="success"> Статистика </CButton>
      </router-link>
      <br />
      <br />
      <CDataTable
        :items="participants.items"
        :fields="fields"
        hover
        border
        sorter
        :tableFilter = "{label: 'Поиск', placeholder: 'найти...'}"
      >
      </CDataTable>
    </CCardBody>
  </div>
</template>

<style>
.test {
  background-color: red;
}
.c-card-body {
  background-color: #fefefe;
}
table {
  font-family: "Lucida Sans Unicode", "Lucida Grande", Sans-Serif;
  font-size: 14px;
  background: white;
  max-width: 100%;
  width: 100%;
  border-collapse: collapse;
  text-align: left;
}
th {
  font-weight: normal;
  color: #039;
  border-bottom: 2px dashed #6678b1;
  padding: 10px 8px;
}
.td {
  color: #669;
  padding: 9px 8px;
  transition: 0.3s linear;
}
tr:hover .td {
  background: #e8edff;
}
.td {
  border-bottom: 0.5px dashed #ccc;
  color: #669;
  padding: 9px 8px;
  transition: 0.3s linear;
}
th:nth-child(-n + 3),
.td:nth-child(-n + 3) {
  text-align: left;
}
.item-right {
  text-align: right;
}
</style>

<script>
import { mapState, mapActions } from "vuex";
const fields = [
  {
    key: "id",
    label: "ID",
  },
  {
    key: "alfabank_user_id",
    label: "Alfabank ID",
  }
];
export default {
  name: "Participants",
  data() {
    return {
      fields,
    };
  },
  computed: {
    ...mapState({
      participants: (state) => state.participants.all,
    }),
  },
  created() {
    this.getAll();
  },
  methods: {
    ...mapActions("participants", ["getAll"]),
  },
};
</script>
